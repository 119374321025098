// the following are polyfills for old browser (IE) support
import "whatwg-fetch";
import "core-js";
import { polyfill as closestpolyfill } from "./elementClosestPolyfill";

function applyPolyfills() {
  // old browsers (IE) are missing some ES features, so we pollyfill some

  // the `import 'whatwg-fetch' above auto-applies polyfills for window.fetch
  // the `import 'core-js' above auto-applies polyfills for most ES features

  closestpolyfill();
}

applyPolyfills();
